import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Box, Text, Checkbox, Heading, Spinner } from '@chakra-ui/react'
import FormInput from 'lib/components/FormInput'
import ModalWrapper from 'lib/components/ModalWrapper'
import IOrganization, {
  IOrgOnboarding,
} from 'domains/organization/models/IOrganization'
import { UploadSegmentAsset } from '@/pauses/components/UploadAsset'
import colors from 'lib/styles/colors'

const validationSchema = Yup.object().shape({
  welcomeScreen: Yup.object().shape({
    enabled: Yup.boolean().required(),
    backgroundMusicUrl: Yup.string(),
    videoUrlNewUsers: Yup.string().required(),
    videoUrlReturningUsers: Yup.string().required(),
  }),
  socialProof: Yup.object().shape({
    totalPauseUsers: Yup.number().required(),
  }),
})

interface IProps {
  org: IOrganization
  updateOrg: (d: any) => Promise<IOrganization>
  close: () => any
}

export default function OnboardingEditorModal(props: IProps) {
  const modalTitle = 'Onboarding'

  const onSubmit = async (
    onboarding: IOrgOnboarding,
    bag: FormikHelpers<IOrgOnboarding>,
  ) => {
    await props.updateOrg({ onboarding })
    bag.setSubmitting(false)
    props.close()
  }

  if (!props.org.onboarding) {
    return (
      <ModalWrapper
        title={modalTitle}
        isOpen={true}
        pending={true}
        actions={[]}
      >
        <Spinner />
      </ModalWrapper>
    )
  }
  return (
    <Formik
      initialValues={props.org.onboarding}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {bag => (
        <ModalWrapper
          title={modalTitle}
          isOpen={true}
          pending={bag.isSubmitting}
          actions={[
            {
              text: 'Save',
              onClick: bag.handleSubmit,
              extra: { type: 'submit', disabled: !bag.isValid },
            },
            {
              text: 'Cancel',
              colorScheme: 'gray',
              onClick: props.close,
              extra: { type: 'button', isLoading: false },
            },
          ]}
        >
          {/* ----- Welcome Screen ----- */}
          <Heading as="h1" size="md" noOfLines={1}>
            Welcome Screen
          </Heading>
          <Box ml={4}>
            {/* Enabled Flag */}
            <Checkbox
              mt={3}
              isChecked={bag.values.welcomeScreen.enabled}
              onChange={bag.handleChange}
              name="welcomeScreen.enabled"
            >
              Enabled
            </Checkbox>

            {/* Background Music */}
            <Box mt={3}></Box>
            <UploadSegmentAsset
              type="MUSIC"
              label="Background Music"
              url={bag.values.welcomeScreen.backgroundMusicUrl}
              onUrlChanged={(url: any) => {
                bag.setFieldValue('welcomeScreen.backgroundMusicUrl', url)
              }}
            />
            {bag.errors.welcomeScreen?.backgroundMusicUrl && (
              <Text color={colors.error} mt={2}>
                {bag.errors.welcomeScreen.backgroundMusicUrl}
              </Text>
            )}

            {/* Video: for new users */}
            <Box mt={3}></Box>
            <UploadSegmentAsset
              type="VIDEO"
              label="Video: for new users"
              url={bag.values.welcomeScreen.videoUrlNewUsers}
              onUrlChanged={(url: any) => {
                bag.setFieldValue('welcomeScreen.videoUrlNewUsers', url)
              }}
            />
            {bag.errors.welcomeScreen?.videoUrlNewUsers && (
              <Text color={colors.error} mt={2}>
                {bag.errors.welcomeScreen.videoUrlNewUsers}
              </Text>
            )}

            {/* Video: for returning users */}
            <Box mt={3}></Box>
            <UploadSegmentAsset
              type="VIDEO"
              label="Video: for returning users"
              url={bag.values.welcomeScreen.videoUrlReturningUsers}
              onUrlChanged={(url: any) => {
                bag.setFieldValue('welcomeScreen.videoUrlReturningUsers', url)
              }}
            />
            {bag.errors.welcomeScreen?.videoUrlReturningUsers && (
              <Text color={colors.error} mt={2}>
                {bag.errors.welcomeScreen.videoUrlReturningUsers}
              </Text>
            )}
          </Box>

          {/* ----- Social Proof ----- */}
          <Heading as="h1" size="md" mt="5" noOfLines={1}>
            Social Proof
          </Heading>
          <Box ml={4}>
            <FormInput
              label="Total Pause Users"
              name="socialProof.totalPauseUsers"
              onChange={bag.handleChange}
              value={bag.values.socialProof.totalPauseUsers}
            />

            {!bag.isValid && <Text>{JSON.stringify(bag.errors)}</Text>}
          </Box>
        </ModalWrapper>
      )}
    </Formik>
  )
}
