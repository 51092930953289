import React from 'react'
import {
  ArrowUpIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  DeleteIcon,
  MinusIcon,
  RepeatClockIcon,
} from '@chakra-ui/icons'
import { FormLabel, Switch, Tooltip } from '@chakra-ui/react'
import clsx from 'clsx'
import MDEditor from '@uiw/react-md-editor'
import { Formik, FormikHelpers, Form, FormikProps } from 'formik'
import * as Yup from 'yup'

import {
  getPauseTranscriptPath,
  IPauseTranscript,
} from '@/pauses/models/IPause'
import { milliSecondsToFriendlyTime } from 'lib/utils/time'
import { deleteDoc, updateDoc } from 'lib/api/connection'
import useModalState from 'lib/api/hooks/useModalState'
import Dialog from 'lib/components/Dialog'
import IconButton from '~/components/IconButton'

function IncrementButton(props: { onClick: any; direction: number }) {
  const Icon = props.direction > 0 ? ChevronUpIcon : ChevronDownIcon
  return (
    <div
      className={clsx(
        'px-6 py-0 cursor-pointer flex items-center justify-center',
        'border border-gray-300 hover:shadow hover:bg-gray-300',
      )}
      onClick={props.onClick}
    >
      <Icon
        fontSize="20px"
        name={props.direction > 0 ? 'chevron-up' : 'chevron-down'}
        color="gray.500"
      />
    </div>
  )
}

function IncrementTime(props: {
  name: string
  value: number
  setValues: (name: string, value: any) => any
}) {
  const [inputValue, setInputValue] = React.useState(props.value)
  React.useEffect(() => {
    setInputValue(props.value)
  }, [props.value])

  return (
    <div className="flex-1 flex flex-row items-center space-x-2">
      <p className="text-xs w-16">
        {milliSecondsToFriendlyTime(props.value, true)}
      </p>

      <IncrementButton
        direction={1}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          props.setValues(props.name, props.value - 10)
        }}
      />
      <div className="relative w-24">
        <input
          type="number"
          value={inputValue}
          onChange={e => setInputValue(parseInt(e.target.value))}
          className="rounded p-1 text-xs w-24"
        />
        <Tooltip
          label={
            inputValue !== props.value
              ? 'Apply change (this will move the transcript)'
              : 'Change input value to enable'
          }
          aria-label="Change"
          placement="top"
        >
          <div
            className="absolute top-0 right-1 cursor-pointer"
            onClick={() => {
              props.setValues(props.name, inputValue)
            }}
          >
            <CheckCircleIcon
              color={inputValue !== props.value ? 'green.500' : 'gray.300'}
            />
          </div>
        </Tooltip>
      </div>
      <IncrementButton
        direction={-1}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          props.setValues(props.name, props.value + 10)
        }}
      />
    </div>
  )
}

const validationSchema = Yup.object().shape({
  start: Yup.number()
    .min(0, 'No negative times')
    .required('Required'),
  end: Yup.number()
    .min(0, 'No negative times')
    .required('Required'),
  transcript: Yup.string().required('Required'),
  isParagraphEnd: Yup.boolean(),
})

type Props = {
  pauseId: string
  transcript: IPauseTranscript
  msPerPx: number
  onDeleted: () => Promise<any>
  hasPrevious?: boolean
  onMergeWithPrevious?: () => any
  onSplitTranscript?: () => any
}

interface IDrag {
  /** The initial Y coordinate of the mouse when dragging starts */
  mouseInitialY: number
  /** The initial start value of the transcript  */
  transcriptInitialStartY: number
  /** The initial end value of the transcript */
  transcriptInitialEndY: number
  /** Which values to update */
  update: { start?: boolean; end?: boolean }
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
export default function TranscriptRow({
  pauseId,
  transcript,
  msPerPx,
  onDeleted,
  hasPrevious,
  onMergeWithPrevious,
  onSplitTranscript,
}: Props) {
  /**
   * State
   * ---------------------------------------------------------------------------
   */

  const [deleteOpen, , , toggleDeleteOpen] = useModalState()

  /**
   * Drag and Drop support
   * ---------------------------------------------------------------------------
   */

  // state tracking the active drag; `null` if no drag is active`
  const [activeDrag, setActiveDrag] = React.useState<IDrag | null>(null)

  const onDragStart = (
    e: React.MouseEvent,
    formikProps: FormikProps<IPauseTranscript>,
    /** which values to update */
    update: { start?: boolean; end?: boolean },
    /** target tag name filter: don't start drag unless it matches */
    tagNameFilter?: string,
  ) => {
    if (!!tagNameFilter && (e.target as any)?.tagName !== tagNameFilter) {
      return
    }
    e.stopPropagation()
    setActiveDrag({
      mouseInitialY: e.pageY,
      transcriptInitialStartY: formikProps.values.start,
      transcriptInitialEndY: formikProps.values.end,
      update,
    })
  }

  const onDragMove = (
    e: React.MouseEvent,
    formikProps: FormikProps<IPauseTranscript>,
  ) => {
    if (!activeDrag) {
      return
    }
    e.stopPropagation()
    const dragOffsetY = (e.pageY - activeDrag.mouseInitialY) * msPerPx
    if (activeDrag.update.start) {
      const newStartValue = activeDrag.transcriptInitialStartY + dragOffsetY
      formikProps.setFieldValue('start', newStartValue)
    }
    if (activeDrag.update.end) {
      const newEndValue = activeDrag.transcriptInitialEndY + dragOffsetY
      formikProps.setFieldValue('end', newEndValue)
    }
  }

  const onDragEnd = (e: React.MouseEvent) => {
    if (!activeDrag) {
      return
    }
    e.stopPropagation()
    setActiveDrag(null)
  }

  /**
   * Handler Functions
   * ---------------------------------------------------------------------------
   */

  const onSave = async (
    values: IPauseTranscript,
    bag: FormikHelpers<IPauseTranscript>,
  ) => {
    await updateDoc(getPauseTranscriptPath(pauseId, transcript.id), values)
    bag.resetForm({ values })
    bag.setSubmitting(false)
  }

  const onDelete = async () => {
    console.log(
      'deleting',
      pauseId,
      getPauseTranscriptPath(pauseId, transcript.id),
    )
    await deleteDoc(getPauseTranscriptPath(pauseId, transcript.id))
    await onDeleted()
    toggleDeleteOpen()
  }

  /**
   * Render
   * ---------------------------------------------------------------------------
   */

  return (
    <Formik
      initialValues={transcript}
      onSubmit={onSave}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {formikProps => {
        const height =
          (formikProps.values.end - formikProps.values.start) / msPerPx
        return (
          <Form>
            <div
              className={clsx(
                'bg-gray-100 hover:bg-blue-100',
                'shadow-md hover:shadow-xl',
                !activeDrag ? 'hover:cursor-grab' : 'hover:cursor-grabbing',
                'absolute w-full pt-2 px-4 flex flex-col space-y-2',
                'box-border border border-gray-200',
              )}
              style={{ height, top: formikProps.values.start / msPerPx }}
              onMouseDown={e =>
                onDragStart(e, formikProps, { start: true, end: true }, 'DIV')
              }
              onMouseMove={e => onDragMove(e, formikProps)}
              onMouseUp={onDragEnd}
            >
              <div className="flex flex-row items-center justify-between">
                <IncrementTime
                  name="start"
                  value={formikProps.values.start}
                  setValues={formikProps.setFieldValue}
                />
                <MinusIcon
                  className="hover:cursor-row-resize"
                  color="gray.300"
                  onMouseDown={e =>
                    onDragStart(e, formikProps, { start: true })
                  }
                  onMouseMove={e => onDragMove(e, formikProps)}
                  onMouseUp={onDragEnd}
                />
                <div className="flex-1 flex flex-row items-center justify-end space-x-2">
                  <IconButton
                    icon={CheckCircleIcon}
                    onClick={formikProps.handleSubmit}
                    disabled={!formikProps.dirty}
                    color="green.500"
                    pending={formikProps.isSubmitting}
                    tip="Save"
                  />
                  <IconButton
                    icon={RepeatClockIcon}
                    onClick={formikProps.handleReset}
                    color="blue.500"
                    disabled={!formikProps.dirty}
                    pending={formikProps.isSubmitting}
                    tip="Undo recent changes"
                  />
                  {hasPrevious && (
                    <IconButton
                      icon={ArrowUpIcon}
                      onClick={onMergeWithPrevious}
                      color="blue.500"
                      disabled={formikProps.dirty}
                      pending={formikProps.isSubmitting}
                      tip="Merge with previous"
                    />
                  )}
                  <IconButton
                    icon={CopyIcon}
                    onClick={onSplitTranscript}
                    color="blue.500"
                    disabled={formikProps.dirty}
                    pending={formikProps.isSubmitting}
                    tip="Split"
                  />
                  <IconButton
                    icon={DeleteIcon}
                    onClick={toggleDeleteOpen}
                    color="red.500"
                    disabled={false}
                    pending={formikProps.isSubmitting}
                    tip="Delete"
                  />
                </div>
              </div>

              <div className="flex flex-col flex-1 w-full">
                <MDEditor
                  value={formikProps.values.transcript}
                  onChange={(newValue?: string | undefined) =>
                    formikProps.setFieldValue('transcript', newValue || '')
                  }
                  height={80}
                  maxHeight={height - 64}
                  preview="edit"
                  hideToolbar
                />
              </div>

              <div className="flex flex-row items-center justify-between">
                <IncrementTime
                  name="end"
                  value={formikProps.values.end}
                  setValues={formikProps.setFieldValue}
                />
                <MinusIcon
                  className="hover:cursor-row-resize"
                  color="gray.300"
                  onMouseDown={e => onDragStart(e, formikProps, { end: true })}
                  onMouseMove={e => onDragMove(e, formikProps)}
                  onMouseUp={onDragEnd}
                />
                <div className="flex-1 flex flex-row items-center justify-end">
                  <FormLabel className="mt-1.5">Is Paragraph End?</FormLabel>
                  <Switch
                    name="isParagraphEnd"
                    isChecked={!!formikProps.values.paragraphEnd}
                    onChange={() =>
                      formikProps.setFieldValue(
                        `paragraphEnd`,
                        !formikProps.values.paragraphEnd,
                      )
                    }
                  >
                    <div />
                  </Switch>
                </div>
              </div>
              {deleteOpen && (
                <Dialog
                  isOpen={deleteOpen}
                  message="Are you sure you want to delete this transcript?"
                  onConfirm={onDelete}
                  onDeny={toggleDeleteOpen}
                  icon="delete"
                />
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
